import React from "react"
import PropTypes from "prop-types"
import { gimmeTheTag } from "../helpers"

const Button = ({ tag, href, className, label, prefix, suffix, ...props }) => {

  const Tag = gimmeTheTag(href, tag)

  return (
    <Tag className={`hcc-btn${className && ` ${className}`}`} href={href} {...props}>
      <span className="hcc-btn-inner">{prefix}{label && <span className="hcc-btn-label">{label}</span>}{suffix}</span>
    </Tag>
  )

}

Button.defaultProps = {
  tag       : 'button',
  href      : null,
  className : '',
  label     : null,
  prefix    : null,
  suffix    : null,
}

Button.propTypes = {
  tag       : PropTypes.string,
  href      : PropTypes.string,
  className : PropTypes.string,
  label     : PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  prefix    : PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  suffix    : PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default Button
