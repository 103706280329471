import React, { useState } from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"
// import FontFaceObserver from "fontfaceobserver"
import SiteHeader from "../components/SiteHeader"
import SiteBody from "../components/SiteBody"
import SiteFooter from "../components/SiteFooter"
import SiteNotice from "../components/SiteNotice"
import SiteLoader from "../components/SiteLoader"
import "../css/site.scss"

const Site = ({ title, titleSuffix, description, url, image, className, noIndex, themeColor, children, ...props }) => {

  // State
  // -----

  const [fontsLoaded, setFontsLoaded] = useState(true)
  const [fontsFailed, setFontsFailed] = useState(true)


  // Contextual classNames
  // ---------------------

  const htmlClassNames = [fontsLoaded ? 'fonts-loaded' : fontsFailed ? 'fonts-failed' : 'fonts-loading']
  const rootClassNames = ['hcc-root']

  if ( className ) {
    rootClassNames.push(className)
  }


  // Font Face Observer
  // ------------------

  // const proximaNova = new FontFaceObserver('proxima-nova');

  // proximaNova.load(null, 2000).then(() => {
  //   setFontsLoaded(true)
  // }, () => {
  //   setFontsFailed(true)
  // })


  // Output
  // ------
  // <!--
  // 01010000 01110010 01101111 01110110 01100101 01110010 01100010 01110011
  // 00100000 00110100 00111010 00110010 00110000 00101101 00110010 00110001
  // -->

  return (
    <StaticQuery
      query={graphql`
        query SiteQuery {
          allDatoCmsGlobal {
            edges {
              node {
                notice
                noticeLink
                noticeAccent {
                  hex
                }
                enableNotice
              }
            }
          }
        }
      `}
      render={data => {
        const titleFormatted = `${title}${titleSuffix}`
        const theOrigin      = process.env.GATSBY_ORIGIN // 'https://www.yourhillside.com'
        const dataGlobal     = data.allDatoCmsGlobal.edges[0].node

        return (
          <div id="hcc-root" className={rootClassNames.join(' ')} {...props}>
            <HelmetDatoCms htmlAttributes={{lang : 'en-US', class : htmlClassNames.join(' ')}}>
              <title>{titleFormatted}</title>

              <meta name="description" content={description}/>
              <meta name="viewport" content="width=device-width, initial-scale=1"/>
              <meta name="format-detection" content="telephone=no"/>

              <meta property="og:type" content="website"/>
              <meta property="og:site_name" content="Hillside Community Church"/>
              <meta property="og:url" content={url}/>
              <meta property="og:title" content={titleFormatted}/>
              <meta property="og:description" content={description}/>
              <meta property="og:image" content={`${theOrigin}${image}`}/>

              <meta property="twitter:card" content="summary_large_image"/>
              <meta property="twitter:url" content={url}/>
              <meta property="twitter:title" content={titleFormatted}/>
              <meta property="twitter:description" content={description}/>
              <meta property="twitter:image" content={`${theOrigin}${image}`}/>

              <link rel="apple-touch-icon" sizes="180x180" href={`${theOrigin}/social/apple-touch-icon.png`}/>
              <link rel="icon" type="image/png" sizes="32x32" href={`${theOrigin}/social/favicon-32x32.png`}/>
              <link rel="icon" type="image/png" sizes="16x16" href={`${theOrigin}/social/favicon-16x16.png`}/>
              <link rel="manifest" href={`${theOrigin}/social/site.webmanifest`}/>
              <link rel="mask-icon" href={`${theOrigin}/social/safari-pinned-tab.svg" color="#5bbad5`}/>
              <link rel="shortcut icon" href={`${theOrigin}/social/favicon.ico`}/>
              <meta name="msapplication-TileColor" content="#da532c"/>
              <meta name="msapplication-config" content={`${theOrigin}/social/browserconfig.xml`}/>
              <meta name="theme-color" content={themeColor}/>

              <link rel="stylesheet" href="https://use.typekit.net/psk4eqh.css"/>
            </HelmetDatoCms>
            {(noIndex === true || props.hasOwnProperty('data-datocms-noindex')) &&
              <HelmetDatoCms>
                <meta name="robots" content="noindex"/>
                <meta name="googlebot" content="noindex"/>
              </HelmetDatoCms>
            }
            {dataGlobal.enableNotice &&
              <SiteNotice
                href    = {dataGlobal.noticeLink !== '' ? dataGlobal.noticeLink : null}
                message = {dataGlobal.notice}
                style   = {{ '--c-base-accent' : dataGlobal.noticeAccent.hex }}
              />
            }
            <SiteLoader/>
            <div className="hcc-site">
              <SiteHeader/>
              <SiteBody>{children}</SiteBody>
              <SiteFooter/>
            </div>
          </div>
        )
      }}
    />
  )

}

Site.defaultProps = {
  title       : 'A Community Church in Keller, TX',
  titleSuffix : ' | Hillside',
  description : 'We exist to help people discover who Jesus really is, and live with, like, and for Him, now and forever.',
  url         : 'https://www.yourhillside.com/',
  image       : '/social/og.png',
  className   : null,
  noIndex     : false,
  themeColor  : '#ffffff',
  children    : null,
}

Site.propTypes = {
  title       : PropTypes.string,
  titleSuffix : PropTypes.string,
  description : PropTypes.string,
  url         : PropTypes.string,
  image       : PropTypes.string,
  className   : PropTypes.string,
  noIndex     : PropTypes.bool,
  themeColor  : PropTypes.string,
  children    : PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

export default Site
