import React from "react"
import PropTypes from "prop-types"
import Svg from "../components/Svg"
import { ministrySlugs, objAssign } from "../helpers"

const MediaFigure = ({ type, className, style, children }) => {

  let ministries   = ministrySlugs()
  let theClassName = ['hcc-media-figure']

  if ( ministries.includes(type) ) {
    style = objAssign(style, {
      '--media-figure-background-color' : `var(--c-${type})`
    })
  }

  if ( ! children ) {
    theClassName.push(`is-${type}`)
  }

  if ( className ) {
    theClassName.push(className)
  }

  return (
    <figure className={theClassName.join(' ')} style={style}>
      {children ? children : <Svg type={type}/>}
    </figure>
  )

}

MediaFigure.defaultProps = {
  type      : 'menu',
  className : null,
  style     : {},
  children  : null,
}

MediaFigure.propTypes = {
  type      : PropTypes.string,
  className : PropTypes.string,
  style     : PropTypes.object,
  children  : PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.array, PropTypes.object]),
}

export default MediaFigure
