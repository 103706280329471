import React from "react"
import PropTypes from "prop-types"
import MediaFigure from "../components/MediaFigure"
import { gimmeTheTag } from "../helpers"

const MediaFigureLink = ({ href, className, figure, label, meta, direction, ellipsis, children, ...props }) => {

  let Tag    = gimmeTheTag(href, 'a')
  let Figure = typeof figure === 'string' ? <MediaFigure type={figure}/> : typeof figure === 'object' ? <MediaFigure className="has-base-bg">{figure}</MediaFigure> : null

  return (
    <Tag className={`hcc-media-figure-link${ellipsis ? ' has-ellipsis' : ''}${className && ` ${className}`}`} href={href} {...props}>
      {Figure && Figure}
      <span className={`hcc-media-figure-link-content is-${direction}`}>
        {label && <span className="hcc-media-figure-link-label">{label}</span>}
        {meta && <span className="hcc-media-figure-link-meta">{meta}</span>}
      </span>
      {children}
    </Tag>
  )

}

MediaFigureLink.defaultProps = {
  href      : '/',
  className : '',
  figure    : 'logo-symbol',
  label     : null,
  meta      : null,
  direction : 'normal',
  ellipsis  : true,
  children  : null,
}

MediaFigureLink.propTypes = {
  type      : PropTypes.string,
  className : PropTypes.string,
  figure    : PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.array, PropTypes.object]),
  label     : PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.array, PropTypes.object]),
  meta      : PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.array, PropTypes.object]),
  direction : PropTypes.string,
  ellipsis  : PropTypes.bool,
  children  : PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

export default MediaFigureLink
