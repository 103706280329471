import React, { useState } from "react"
import PropTypes from "prop-types"


// Output
// ------
// 01. Real people should not fill this in and expect good things - do not
//     remove this or risk form bot signups.
// 02. <link href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css" rel="stylesheet" type="text/css"/>
// 03. <label for="mce-EMAIL">Email Address</label>

const Mailchimp = () => {
  const [theEmail, setEmail] = useState("")

  return (
    <>
      <div id="mc_embed_signup">
        <form action="https://yourhillside.us13.list-manage.com/subscribe/post?u=2062fd954cf5937681d04bf6e&amp;id=4d3098d3a3" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
          <div id="mc_embed_signup_scroll">
            <h2>Subscribe to Hillside's Weekly email to receive information about upcoming events and announcements.</h2>
            <div className="hcc-mailchimp-fields">
              <div className="mc-field-group">
                <input
                  id          = "mce-EMAIL"
                  className   = {theEmail !== "" ? "required email is-active" : "required email"}
                  name        = "EMAIL"
                  type        = "email"
                  value       = {theEmail}
                  placeholder = "Email Address"
                  onChange    = {e => { setEmail(e.target.value) }}
                />
              </div>
              <div id="mce-responses" className="clear">
                <div className="response" id="mce-error-response" style={{display : "none"}}></div>
                <div className="response" id="mce-success-response" style={{display : "none"}}></div>
              </div> {/* 01 */}
              <div style={{position : "absolute", left : "-5000px"}} aria-hidden="true">
                <input type="text" name="b_2062fd954cf5937681d04bf6e_4d3098d3a3" tabIndex="-1" value="" onChange={() => {}}/>
              </div>
              <div className="clear">
                <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className={theEmail !== "" ? "button is-active" : "button"}/>
              </div>
            </div>
          </div>
        </form>
      </div>
      <script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script>
      <script type="text/javascript">{`(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text'; }(jQuery));var $mcj = jQuery.noConflict(true);`}</script>
    </>
  )
}

Mailchimp.defaultProps = {
  tag       : 'div',
  type      : null,
  className : null,
  source    : ''
}

Mailchimp.propTypes = {
  tag       : PropTypes.string,
  type      : PropTypes.string,
  className : PropTypes.string,
  source    : PropTypes.string,
}

export default Mailchimp
