import React from "react"
import PropTypes from "prop-types"

const Section = ({ tag, children, className, ...props }) => {
  const Tag = tag

  return (
    <Tag className={`hcc-section${className && ` ${className}`}`} {...props}>
      {children}
    </Tag>
  )
}

Section.defaultProps = {
  tag       : 'section',
  className : '',
  children  : null,
}

Section.propTypes = {
  tag       : PropTypes.string,
  className : PropTypes.string,
  children  : PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

export default Section
