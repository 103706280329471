import React from "react"
import PropTypes from "prop-types"
import Doom from "../components/Doom"
import { gimmeTheTag } from "../helpers"

const SiteNotice = ({ tag, href, message, children, ...props }) => {

  const Tag = gimmeTheTag(href, tag)

  // is-toast

  return (
    <Tag className="hcc-notice" href={href} {...props}>
      <Doom tag="span" content={message}/>
      {children}
    </Tag>
  )

}

SiteNotice.defaultProps = {
  tag      : 'div',
  href     : null,
  message  : null,
  children : null,
}

SiteNotice.propTypes = {
  tag      : PropTypes.string,
  href     : PropTypes.string,
  message  : PropTypes.string,
  children : PropTypes.object,
}

export default SiteNotice

