// =============================================================================
// HOOKS.JS
// -----------------------------------------------------------------------------
// I get by with a little help from my friends...
// =============================================================================

// =============================================================================
// TABLE OF CONTENTS
// -----------------------------------------------------------------------------
//   01. Imports
//   02. useRandomID()
//   03. usePrevious()
//   04. useSearch()
//   05. useProviderSearch()
// =============================================================================

// Imports
// =============================================================================

import React, { useCallback, useEffect, useRef, useState } from "react"
import { buildClient } from "@datocms/cma-client-browser"
import shortid from "shortid"



// useRandomID()
// =============================================================================

export function useRandomID() {
  const [id, setId] = useState(null)
  useEffect(() => {
    setId(shortid.generate())
  }, [])
  return id
}



// usePrevious()
// =============================================================================

export function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}



// useSearch()
// =============================================================================

export function useSearch(searchCallback, debounce = 1000, minLength = 3) {
  const [results, setResults]         = useState(null)
  const [isSearching, setIsSearching] = useState(false)
  const [query, setQuery]             = useState("")
  /* eslint-disable-next-line */
  const doSearch                      = useCallback(searchCallback, []) // make sure callback doesn't change between renders
  const ref                           = useRef(null)

  // Control Text Input
  // ------------------
  // 01. Track the last keystroke
  // 02. If the input changed, we are searching. Otherwise it could just be an arrow key.
  // 03. Run a debounced search if there is a query.
  // 04. Only update on keystrkes within the debounce timer.
  // 05. If the user quickly cleared the input, reset our state

  useEffect(() => {

    if ( ! ref.current ) {
      return () => {}
    }

    const input = ref.current
    let timer   = null
    let prev    = "" // 01

    const onChange = e => {
      if (prev !== e.target.value) { // 02
        clearTimeout(timer)
        if (e.target.value) { // 03
          setIsSearching(true)
          timer = setTimeout(() => {
            setQuery(e.target.value) // 04
          }, debounce)
        } else { // 05
          setQuery("")
          setResults(null)
          setIsSearching(false)
        }
      }
      prev = e.target.value
    }
    input.addEventListener("keyup", onChange)

    return () => {
      clearTimeout(timer)
      input.removeEventListener("keyup", onChange)
    }

  }, [debounce])


  // Perform the Search When Query Changes
  // -------------------------------------
  // If the minimum length isn't met, it will still pretend to search but set
  // the results to empty (no API calls made)

  useEffect(() => {
    let cancel = false;
    if ( query?.length >= minLength ) {
      doSearch(query).then(response => {
        if (!cancel && Array.isArray(response)) {
          setResults(response)
        }
      })
      .catch((error) => {
        console.error(error)
      }).finally(() => {
        if (!cancel) {
          setIsSearching(false)
        }
      })
    } else {
      setResults(null)
      setIsSearching(false)
    }
    return () => {
      // The query changed - ignore any async response from here to ensure only the final request is considered
      cancel = true;
    }
  }, [query, setResults, setIsSearching, doSearch, minLength])


  // Clear Query
  // -----------

  const clearQuery = () => {
    ref.current.value = ""
    setQuery("")
    setIsSearching(false)
    setResults(null)
  }

  return [ref, results, isSearching, clearQuery, query];
}



// useProviderSearch()
// =============================================================================

const highlighter = (text) => text.replace(/\[h\](.+?)\[\/h\]/g, function(a, b) {
  var div = document.createElement('div');
  div.innerHTML = '<strong class="highlight"></strong>';
  div.children[0].innerText = b;
  return div.children[0].outerHTML;
});

const datoClient = typeof window !== 'undefined' ? buildClient( { apiToken: process.env.GATSBY_DATO_CLIENT_API_TOKEN } ) : null;

export function useProviderSearch() {
  return useSearch( async (query) => {
    if (!datoClient) return []; // No client

    const results = await datoClient.searchResults.list({
      filter: {
        query,
        fuzzy: true,
        build_trigger_id: process.env.GATSBY_DATO_BUILD_TRIGGER_ID ?? 'Production',
      },
      page: {
        limit: 20,
        offset: 0,
      },
    })

    return (results ?? []).map(({ highlight, ...result}) => ({
      ...result,
      title: highlight.title ? highlighter(highlight.title[0]) : result.title,
      body: highlight.body
        ? `&hellip;${highlight.body.map(text => highlighter(text.trim())).join("&hellip;")}&hellip;`
        : result.body_excerpt
    }))
  })
}



// useClientEffect()
// =============================================================================

// export function useClientEffect(effect, dependencies = []) {
//   if (typeof window !== 'undefined') return false;
//   return useEffect(() => {});
// }



// useClientLayoutEffect()
// =============================================================================

// export function useClientLayoutEffect(effect, dependencies = []) {
//   if (typeof window !== 'undefined') return false;
//   return useLayoutEffect(effect, dependencies);
// }
