import React from "react"
import Svg from "../components/Svg"

const SiteLoader = () => {
  return (
    <div className="hcc-loader">
      <div className="hcc-loader-graphic">
        <Svg type="logo-symbol"/>
      </div>
    </div>
  )
}

export default SiteLoader
