import React from "react"
import PropTypes from "prop-types"

const Doom = ({ content, tag, className, ...props }) => {
  let Tag = tag
  return (
    <Tag
      className={className}
      dangerouslySetInnerHTML={{ __html : content }}
      {...props}
    />
  )
}

Doom.propTypes = {
  content   : PropTypes.string,
  tag       : PropTypes.string,
  className : PropTypes.string,
}

Doom.defaultProps = {
  content   : '',
  tag       : 'div',
  className : '',
}

export default Doom
