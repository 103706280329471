import React from "react"
import PropTypes from "prop-types"

const SiteBody = ({ children }) => {
  return (
    <main className="hcc-body">
      {children}
    </main>
  )
}

SiteBody.propTypes = {
  children : PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

export default SiteBody
