import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const SiteLink = ({href, children, ...props}) => {
  return (
    <Link to={href} {...props}>{children}</Link>
  )
}

SiteLink.propTypes = {
  href     : PropTypes.string,
  children : PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.object]),
}

export default SiteLink

// import { Link } from "gatsby"
// <Link to={href} {...props}>{children}</Link>

// import TransitionLink from 'gatsby-plugin-transition-link'

    // <TransitionLink
    //   exit={{
    //     delay  : 0,
    //     length : 0.4,
    //   }}
    //   entry={{
    //     delay  : 0.4,
    //     length : 0.4,
    //   }}
    //   to={href}
    //   {...props}
    // >
    //   {children}
    // </TransitionLink>
