import React from "react"
import PropTypes from "prop-types"

const Container = ({tag, size, className, children, ...props}) => {

  const Tag = tag

  return (
    <Tag className={`hcc-container is-${size}${className && ` ${className}`}`} {...props}>
      {children}
    </Tag>
  )

}

Container.defaultProps = {
  tag       : 'div',
  size      : 'xl',
  className : '',
  children  : null,
}

Container.propTypes = {
  tag       : PropTypes.string,
  size      : PropTypes.string,
  className : PropTypes.string,
  children  : PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
}

export default Container
