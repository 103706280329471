import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import * as moment from "moment"
import { some } from "lodash"
import MediaFigureLink from "../components/MediaFigureLink"
import SiteLink from "../components/SiteLink"
import { formatLink, formatTime } from "../helpers"

const ddd = 'YYYY-MM-DD'
const ttt = 'YYYY-MM-DDTHH:mm:ss-Z'

const ComingUp = ({ title, h, showTitle, count, ministry, destination, meta, fallback, accent, children, ...props }) => (
  <StaticQuery
    query={graphql`
      query {
        allDatoCmsEvent(sort: {fields: start, order: ASC}) {
          edges {
            node {
              ...EventFragment
            }
          }
        }
      }
    `}
    render={data => {

      // Setup
      // -----

      let now            = Date.now()
      let isRegistration = destination === 'registration'
      let allEventsLink  = formatLink('', 'e')
      let TitleTag       = `h${h}`

      let allFutureEvents = [...data.allDatoCmsEvent.edges].filter((el, i, arr) => {
        if ( ministry !== null ) {
          return moment(arr[i].node.start, ttt).format('x') > now && some(arr[i].node.ministries, ['slug', ministry])
        }
        return moment(arr[i].node.start, ttt).format('x') > now
      })


      // Output
      // ------

      return (
        <div className="hcc-coming-up" {...props} data-datocms-noindex>
          {allFutureEvents.length > 0 &&
            <>
              {showTitle &&
                <TitleTag className="hcc-coming-up-title">
                  <span>{title}</span>
                  <SiteLink href={allEventsLink}>See All</SiteLink>
                </TitleTag>
              }
              <ol>
                {allFutureEvents.slice(0, count).map(({node : event}) => {
                  return (
                    <li key={`${ministry !== null ? ministry : 'all'}-${event.id}`}>
                      <MediaFigureLink
                        href   = {isRegistration ? event.registrationLink : formatLink(`${event.slug}-${moment(event.start, ttt).format(ddd)}`, 'e')}
                        figure = {formatTime(event.start, 'eventDateWithMonth')}
                        label  = {event.title}
                        meta   = {meta && <span>{formatTime(event.start, 'timeLong')} at {event.location.title}</span>}
                        target = {isRegistration ? "_blank" : null}
                        rel    = {isRegistration ? "noopener noreferrer" : null}
                        style  = {accent ? { '--c-base-accent' : `var(--c-${event.ministries[0].slug})` } : null}
                      />
                    </li>
                  )
                })}
              </ol>
            </>
          }
          {allFutureEvents.length === 0 && fallback}
          {children}
        </div>
      )

    }}
  />
)

ComingUp.defaultProps = {
  title       : 'Coming Up',
  h           : '2',
  showTitle   : false,
  count       : 3,
  ministry    : null,
  destination : 'onsite',
  meta        : true,
  fallback    : null,
  accent      : false,
  children    : null,
}

ComingUp.propTypes = {
  title       : PropTypes.string,
  h           : PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showTitle   : PropTypes.bool,
  count       : PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ministry    : PropTypes.string,
  destination : PropTypes.string,
  meta        : PropTypes.bool,
  fallback    : PropTypes.object,
  accent      : PropTypes.bool,
  children    : PropTypes.object,
}

export default ComingUp


// Backups
// -------

// '--media-figure-border-color'     : `var(--c-${event.ministries[0].slug})`,
// '--media-figure-gradient-stop-01' : `var(--c-${event.ministries[0].slug})`,
// '--media-figure-gradient-stop-02' : `var(--c-${event.ministries[0].slug})`,

// const token = 'a0c2e3e1c8ccd23b54703c982b182b'
// fetch('https://graphql.datocms.com/', {
//   method  : 'POST',
//   headers : {
//     'Content-Type'  : 'application/json',
//     'Accept'        : 'application/json',
//     'Authorization' : `Bearer ${token}`,
//   },
//   body : JSON.stringify({
//     query : `{ allEvents { id } }`
//   })
// })
// .then(res => res.json())
// .then(res => console.log('data returned:', res));

// allDatoCmsEvent(filter: {ministries: {elemMatch: {slug: {eq: $slug}}}}, limit: 3) {
//   edges {
//     node {
//       ...EventFragment
//     }
//   }
// }
